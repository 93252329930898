<template>
  <div class="bg-white border border-primary border-2">
    <div class="p-3 p-lg-5 col-12 col-lg-9 mx-auto">
      <div class="row mb-4 align-items-center g-0">
        <InputPassword
          id="originPassword"
          v-model:input="state.originPassword"
          type="text"
          label="目前密碼"
          direction="row"
          :required="true"
          autocomplete="on"
          placeholder="請輸入原密碼"
          :err-message="v$.originPassword?.$errors[0]?.$message || ''"
          @focus="v$.originPassword.$touch"
          @enter="updateMemberPassword"
        />
      </div>
      <div class="row mb-4 align-items-center g-0">
        <InputPassword
          id="resetPassword"
          v-model:input="state.resetPassword"
          type="text"
          label="新密碼"
          direction="row"
          :required="true"
          autocomplete="on"
          placeholder="請輸入新密碼"
          :err-message="v$.resetPassword?.$errors[0]?.$message || ''"
          @focus="v$.resetPassword.$touch"
          @enter="updateMemberPassword"
        />
      </div>
      <div class="row mb-4 align-items-center g-0">
        <InputPassword
          id="resetCheckPassword"
          v-model:input="state.resetCheckPassword"
          type="text"
          label="再次輸入密碼"
          direction="row"
          :required="true"
          autocomplete="on"
          placeholder="請再次輸入密碼"
          :err-message="v$.resetCheckPassword?.$errors[0]?.$message || ''"
          @focus="v$.resetCheckPassword.$touch"
          @enter="updateMemberPassword"
        />
      </div>
      <div class="d-flex justify-content-center my-3">
        <button
          class="btn btn-primary text-white rounded-0 ms-2"
          type="button"
          @click="updateMemberPassword"
        >
          儲存
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, passwordHelper, helpers, sameAs } from '@/hooks/useVuelidate.js'
import { useStore } from 'vuex'
import { computed, reactive, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'MemberPassword',
  components: {
    InputPassword

  },
  setup (props) {
    const { getters, dispatch, commit } = useStore()
    const { push } = useRouter()
    const { getErrorMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])

    const setAlert = inject('setAlert')

    const resetPassword = ref('')
    const state = reactive({
      originPassword: '',
      resetPassword: resetPassword,
      resetCheckPassword: ''
    })

    const rules = {
      originPassword: { requiredHelper },
      resetPassword: { requiredHelper, passwordHelper },
      resetCheckPassword: {
        requiredHelper,
        passwordHelper,
        sameAsRef: helpers.withMessage('請確認兩次密碼是否一致', sameAs(resetPassword))
      }
    }

    const v$ = useVuelidate(rules, state)

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCart', { data: [] })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      push('/login')
    }

    const updateMemberPassword = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        const payload = {
          memberID: memberID.value,
          originPassword: state.originPassword,
          resetPassword: state.resetPassword
        }

        try {
          const response = await dispatch('member/updateMemberPassword', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, '更改密碼成功，請重新登入')
            logoutMember()
          }

          return response
        } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, `更改密碼失敗: ${error}`)
        }
      }
    }

    return { v$, state, updateMemberPassword }
  }
}
</script>
<style lang="scss" module>

</style>
